// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

const initialState = {
  createLoading: false,
  fetchLoading: false,
  createError: null,
  fetchError: null,
  message: null,
  users: []
};

export const deleteEmployee = createAsyncThunk('/employees/deleteEmployee', async ({ email, accessToken }) => {
  try {
    const config = {
      headers: {
        'Authorization': `${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post('/users/delete', { email }, config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to load employee');
  }
})

export const addEmployee = createAsyncThunk('employee/addEmployee', async (data) => {
  try {
    const config = {
      headers: {
        'Authorization': `${data.accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post('/users', data.userData, config);
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || 'Failed to add employee');
  }
});

export const fetchEmployee = createAsyncThunk('employee/fetchEmployee', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.get('/users', config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to load employee');
  }
});

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.message = action.payload.message;
        alert(state.message);
      })
      .addCase(addEmployee.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.createLoading = false;
        state.message = action.payload.message;
        state.createError = null;
        alert(state.message);
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.error.message;
        state.message = action.error.message;
        alert(state.createError);
      })
      .addCase(fetchEmployee.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.users = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchEmployee.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.users = initialState.users;
        state.message = null;
      });
  },
});

export default employeeSlice.reducer;
