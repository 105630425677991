// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

const initialState = {
  email: null,
  loading: false,
  message: null,
  error: null,
};

export const forgetPassword = createAsyncThunk('forgetPassword/', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post('/users/forgot-password', data, config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to signup');
  }
});

const forgetPasswordSlice = createSlice({
  name: 'forgetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
  },
});

export default forgetPasswordSlice.reducer;
