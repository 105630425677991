import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showError, showSuccess } from '../../helpers/toast';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);



export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${userData.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.put(`/users/${userId}`, userData, config);
      showSuccess('Update Successfully!');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update user');
    }
  }
);

export const getUser = createAsyncThunk(
  'users/getUser',
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${userData.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.get(`/users/${userId}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user');
    }
  }
);

// Async thunk for deleting a company
export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({configdata}, { rejectWithValue }) => { 
    try {
      const config = {
        headers: {
          'Authorization': `${configdata.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.delete(`/users/${configdata.user_id}`, config);
      showSuccess('Delete Successfully')
      return configdata.company_id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete user');
    }
  }
);

export const getallUser = createAsyncThunk(
  'users/getallUser',
  async (authToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${authToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.get(`/users/`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user');
    }
  }
);

const initialState = {
    getuserdata: {}, 
    getalluserdata: [],
    loading: false,
    error: null,
    message: null,
  };

const userSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload?.message; 
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.getuserdata = action.payload;
        state.error = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(getallUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getallUser.fulfilled, (state, action) => {
        state.loading = false;
        state.getalluserdata = action.payload;
        state.error = null;
      })
      .addCase(getallUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.getalluserdata = state.getalluserdata.filter(user => user.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export default userSlice.reducer;
