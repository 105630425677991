import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import iconAddMore from "../../../../assets/media/icon-add-more.svg";
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';
import iconEditHead from "../../../../assets/media/icon-editor-header.svg";

const Fields = () => {
    const dispatch = useDispatch()
    return (
        <Fragment>
            <div className='editorHeader'>
                <h2 className='subtitle'>
                    <Link onClick={() => dispatch(setSelectedControl('Controls'))}><img src={iconEditHead} alt="" /></Link>
                    <span>Fields</span>
                </h2>
            </div>

            <div className='editorBody'>
                <div className='itemOptions'>
                    <div className='itemOption'>
                        <label>Margin</label>
                        <div className='itemProp'>
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                        </div>
                        <div className='itemAttr'>
                            <span>Top</span>
                            <span>Right</span>
                            <span>Bottom</span>
                            <span>Left</span>
                        </div>
                    </div>
                    <div className='itemOption'>
                        <label>Horizontal Alignment</label>
                        <select class="form-select">
                            <option selected value="Left">Left</option>
                            <option value="Center">Center</option>
                            <option value="Right">Right</option>
                        </select>
                    </div>
                    <div className='itemOption'>
                        <label>Vertical Alignment</label>
                        <select class="form-select">
                            <option value="Top">Top</option>
                            <option selected value="Middle">Middle</option>
                            <option value="Bottom">Bottom</option>
                        </select>
                    </div>
                    <div className='itemOption inlineOption'>
                        <label className='w60'>Height</label>
                        <input class='form-control' type='number' value='150' />
                    </div>
                    <div className='itemOption inlineOption'>
                        <label className='w60'>Width</label>
                        <input class='form-control' type='number' value='150' />
                    </div>
                    <div className='itemOption separator'>
                    </div>
                    <div className='itemOption'>
                        <label>Validation</label>
                        <select class="form-select">
                            <option value="Alpha">Alpha</option>
                            <option selected value="Alpha Numeric">Alpha Numeric</option>
                            <option value="Numeric">Numeric</option>
                        </select>
                    </div>
                    <div className='itemOption separator'>
                    </div>
                    <div className='itemOption'>
                        <label>Discrepancy Options</label>
                        <input className='form-control' placeholder='Note matching with others' />
                        <Link className='addMoreButton' to={'/'}><img src={iconAddMore} alt="" />Add More</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Fields