import React, { Fragment } from 'react'
import iconEditHead from "../../../../assets/media/icon-editor-header.svg";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';

const Upload = () => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className='editorHeader'>
                <h2 className='subtitle'>
                    <Link onClick={() => dispatch(setSelectedControl('Controls'))}><img src={iconEditHead} alt="" /></Link>
                    <span>Upload</span>
                </h2>
            </div>
            <div className='editorBody'>
                <div className='itemOptions'>
                    <div className='itemOption'>
                        <label>Margin</label>
                        <div className='itemProp'>
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                        </div>
                        <div className='itemAttr'>
                            <span>Top</span>
                            <span>Right</span>
                            <span>Bottom</span>
                            <span>Left</span>
                        </div>
                    </div>
                    <div className='itemOption'>
                        <label>Horizontal Alignment</label>
                        <select className="form-select">
                            <option selected value="Left">Left</option>
                            <option value="Center">Center</option>
                            <option value="Right">Right</option>
                        </select>
                    </div>
                    <div className='itemOption'>
                        <label>Vertical Alignment</label>
                        <select className="form-select">
                            <option value="Top">Top</option>
                            <option selected value="Middle">Middle</option>
                            <option value="Bottom">Bottom</option>
                        </select>
                    </div>
                    <div className='itemOption inlineOption'>
                        <label className='w60'>Height</label>
                        <input class='form-control' type='number' value='150' />
                    </div>
                    <div className='itemOption separator'>
                    </div>
                    <div className='itemOption'>
                        <label>Allow files</label>
                        <div className='form-check-wrapper'>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="JPG" checked />
                                <label class="form-check-label">JPG</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="PNG" checked />
                                <label class="form-check-label">PNG</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="CSV" />
                                <label class="form-check-label">CSV</label>
                            </div>
                        </div>
                        <div className='form-check-wrapper'>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="DOC" />
                                <label class="form-check-label">DOC</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="PDF" />
                                <label class="form-check-label">PDF</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="XLS" checked />
                                <label class="form-check-label">XLS</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default Upload