// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

const initialState = {
  data: null,
  signupcode: null,
  password: null,
  full_name: null,
  phone: null,
  companyRegistrationNo: null,
  loading: false,
  message: null,
  error: null,
  isSuccess: false
};

export const signUp = createAsyncThunk('signUp/', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post('/users/signup', data.userData, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error || 'Failed to signup');
  }
});

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    updateSignUpData: (state, action) => {
      state.signupcode = action.payload.signupcode;
      state.password = action.payload.password;
      state.full_name = action.payload.full_name;
      state.phone = action.payload.phone;
      state.companyRegistrationNo = action.payload.companyRegistrationNo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action;
        state.message = action.payload;
        state.error = null;
        state.isSuccess = true
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isSuccess = true
        // alert(action.error.message)
        state.data = action;

        state.loading = false;
        state.error = action.error;
      })
  },
});

export const { updateSignUpData } = signUpSlice.actions;
export default signUpSlice.reducer;
