// reducers/feedbackSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({

  baseURL: process.env.REACT_APP_BACKEND_URL // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  message: ""
};

export const sendFeedback = createAsyncThunk('sendFeedback', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.post('/feedbacks', data.feedbackData, config);
    data.resetForm();
    alert("Feedback sent successfully")
    return response.data;
  } catch (error) {
    alert("Feedback failed to send")
    throw new Error('Failed to load feedbacks');
  }
});

const feedbackSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.fetchLoading = true;
        state.message = "";
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = null;
        state.message = "Feedback submitted successfully";
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
        state.message = "";
      });
  },
});

export default feedbackSlice.reducer;
