import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showError, showSuccess } from '../../helpers/toast';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    // Display error message
    console.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

// Async thunk for creating a notification
export const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async ({ message, user_id, accessToken,is_admin }, { rejectWithValue }) => {
    try {
      console.log(message,user_id)
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.post('/notification/', { message, user_id ,is_admin}, config);
      // showSuccess('Notification created successfully');
      return response.data;
    } catch (error) {
      showError(error.response.data.error);
      return rejectWithValue(error.response?.data?.message || 'Failed to create notification');
    }
  }
);

// Async thunk for updating a notification
export const updateNotification = createAsyncThunk(
  'notifications/updateNotification',
  async ({ notificationId, message, user_read_status, accessToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.put(`/notification/${notificationId}`, { message, user_read_status }, config);
    //   showSuccess('Notification updated successfully');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update notification');
    }
  }
);

// Async thunk for deleting a notification
export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async ({configdata}, { rejectWithValue }) => { 
    console.log(configdata.notification_ids,"nnnnnnnnnnnnnnnnn")
    try {
      const config = {
        headers: {
          'Authorization': `${configdata.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.delete(`/notification/${configdata.notification_ids}`, config);
    //   showSuccess('Notification deleted successfully');
      return configdata.notification_ids;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete notification');
    }
  }
);

// Async thunk for fetching notifications
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (accessToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.get('/notification/', config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch notifications');
    }
  }
);

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: {
    [createNotification.pending]: (state) => {
      state.loading = true;
    },
    [createNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications.push(action.payload);
      state.error = null;
    },
    [createNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateNotification.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.notifications.findIndex(notification => notification.id === action.payload.id);
      if (index !== -1) {
        state.notifications[index] = action.payload;
      }
      state.error = null;
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
      state.error = null;
    },
    [fetchNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
      state.error = null;
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default notificationSlice.reducer;
