// reducers/formsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: []
};

export const fetchForms = createAsyncThunk('fetchForms', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.get('/forms', config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to load forms');
  }
});

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForms.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchForms.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      });
  },
});

export default formsSlice.reducer;
