// reducers/certificateSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({

    baseURL: process.env.REACT_APP_BACKEND_URL // Replace this with your API's base URL

    // Other default configurations (headers, timeout, etc.) can be set here if needed
});

const initialState = {
    data: [],
    loading: false,
    error: null,
    data: []
};

export const fetchCertificates = createAsyncThunk('fetchCertificates', async (data) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${data.accessToken}`,
            },
        };
        const response = await API.get('/certificates', config);
        return response.data;
    } catch (error) {
        throw new Error('Failed to load feedbacks');
    }
});

const certificateSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCertificates.pending, (state) => {
                state.fetchLoading = true;
                state.message = "";
            })
            .addCase(fetchCertificates.fulfilled, (state, action) => {
                state.fetchLoading = false;
                state.fetchError = null;
                state.data = action.payload;
            })
            .addCase(fetchCertificates.rejected, (state, action) => {
                state.fetchLoading = false;
                state.fetchError = action.error.message;
                state.data = [];
                state.message = null;
                state.message = "";
            });
    },
});

export default certificateSlice.reducer;
