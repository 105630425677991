import React, { Fragment } from 'react'
import iconAddMore from "../../../../assets/media/icon-add-more.svg";
import { Link } from 'react-router-dom';
import iconEditHead from "../../../../assets/media/icon-editor-header.svg";
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';

const Images = () => {
    const dispatch = useDispatch();
    return (<Fragment>
        <div className='editorHeader'>
            <h2 className='subtitle'>
                <Link onClick={() => dispatch(setSelectedControl('Controls'))}><img src={iconEditHead} alt="" /></Link>
                <span>Images</span>
            </h2>
        </div>
        <div className='editorBody'>
            <div className='itemOptions'>
                <div className='itemOption'>
                    <label>Margin</label>
                    <div className='itemProp'>
                        <input className='form-control' type='text' value='0' />
                        <input className='form-control' type='text' value='0' />
                        <input className='form-control' type='text' value='0' />
                        <input className='form-control' type='text' value='0' />
                    </div>
                    <div className='itemAttr'>
                        <span>Top</span>
                        <span>Right</span>
                        <span>Bottom</span>
                        <span>Left</span>
                    </div>
                </div>
                <div className='itemOption'>
                    <label>Horizontal Alignment</label>
                    <select class="form-select">
                        <option selected value="Left">Left</option>
                        <option value="Center">Center</option>
                        <option value="Right">Right</option>
                    </select>
                </div>
                <div className='itemOption'>
                    <label>Vertical Alignment</label>
                    <select class="form-select">
                        <option value="Top">Top</option>
                        <option selected value="Middle">Middle</option>
                        <option value="Bottom">Bottom</option>
                    </select>
                </div>
                <div className='itemOption separator'>
                </div>
                <div className='itemOption'>
                    <label>Choose Image</label>
                    <Link className='uploadButton' to={'/'}><img src={iconAddMore} alt="" />Upload</Link>
                </div>
                <div className='itemOption inlineOption'>
                    <label className='w60'>Height</label>
                    <input class='form-control' type='number' value='150' />
                </div>
                <div className='itemOption inlineOption'>
                    <label className='w60'>Width</label>
                    <input class='form-control' type='number' value='150' />
                </div>
            </div>
        </div>
    </Fragment>

    )
}

export default Images