import React, { Fragment } from 'react'
import iconEditorListOrdered from "../../../../assets/media/icon-editor-list-ordered.svg";
import iconEditorListUnordered from "../../../../assets/media/icon-editor-list-unordered.svg";
import iconEditorTextBold from "../../../../assets/media/icon-editor-text-bold.svg";
import iconEditorTextItalic from "../../../../assets/media/icon-editor-text-italic.svg";
import iconEditorTextJustified from "../../../../assets/media/icon-editor-text-justified.svg";
import iconEditorTextRight from "../../../../assets/media/icon-editor-text-right.svg";
import iconEditorTextCenter from "../../../../assets/media/icon-editor-text-center.svg";
import iconEditorTextLeft from "../../../../assets/media/icon-editor-text-left.svg";
import iconEditHead from "../../../../assets/media/icon-editor-header.svg";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';

const Texts = () => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className='editorHeader'>
                <h2 className='subtitle'>
                    <Link onClick={() => dispatch(setSelectedControl('Controls'))}><img src={iconEditHead} alt="" /></Link>
                    <span>Texts</span>
                </h2>
            </div>
            <div className='editorBody'>
                <div className='itemOptions'>
                    <div className='itemOption'>
                        <label>Margin</label>
                        <div className='itemProp'>
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                        </div>
                        <div className='itemAttr'>
                            <span>Top</span>
                            <span>Right</span>
                            <span>Bottom</span>
                            <span>Left</span>
                        </div>
                    </div>
                    <div className='itemOption'>
                        <label>Horizontal Alignment</label>
                        <select class="form-select">
                            <option selected value="Left">Left</option>
                            <option value="Center">Center</option>
                            <option value="Right">Right</option>
                        </select>
                    </div>
                    <div className='itemOption'>
                        <label>Vertical Alignment</label>
                        <select class="form-select">
                            <option value="Top">Top</option>
                            <option selected value="Middle">Middle</option>
                            <option value="Bottom">Bottom</option>
                        </select>
                    </div>
                    <div className='itemOption inlineOption'>
                        <label>Height</label>
                        <input class='form-control' type='number' value='150' />
                    </div>
                    <div className='itemOption separator'>
                    </div>
                    <div className='itemOption'>
                        <label>Font Style</label>
                        <select class="form-select">
                            <option value="Top">Top</option>
                            <option selected value="Middle">Middle</option>
                            <option value="Bottom">Bottom</option>
                        </select>
                    </div>
                    <div className='itemOption pt10'>
                        <label>Text Sizes</label>
                        <div className='itemSizeProp'>
                            <span className='h1'>H1</span>
                            <span className='current h2'>H2</span>
                            <span className='h3'>H3</span>
                            <span className='p'>P</span>
                        </div>
                    </div>

                    <div className='itemOption inlineOption'>
                        <label className='w100'>Font Size</label>
                        <input class='form-control' type='number' value='150' />
                    </div>
                    <div className='itemOption'>
                        <label>Alignment</label>
                        <div className='itemAllign'>
                            <span><img src={iconEditorTextLeft} alt="" /></span>
                            <span><img src={iconEditorTextCenter} alt="" /></span>
                            <span><img src={iconEditorTextRight} alt="" /></span>
                            <span><img src={iconEditorTextJustified} alt="" /></span>
                        </div>
                        <div className='itemAttr itemAllignAttr'>
                            <span>Left</span>
                            <span>Center</span>
                            <span>Right</span>
                            <span>Justified</span>
                        </div>
                        <div className='itemAllign'>
                            <span><img src={iconEditorListUnordered} alt="" /></span>
                            <span><img src={iconEditorListOrdered} alt="" /></span>
                            <span><img src={iconEditorTextBold} alt="" /></span>
                            <span><img src={iconEditorTextItalic} alt="" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default Texts