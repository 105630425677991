// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

const initialState = {
  password: null,
  new_password: null,
  passwordresetcode: null,
  loading: false,
  message: null,
  error: null,
};

export const resetPassword = createAsyncThunk('resetPassword/', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post('/users/reset-password', data, config);
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || 'Failed to Rest password');
  }
});

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        alert("Password reset successfully, please login to continue")
        state.loading = false;
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        alert(action.error.message)
        state.loading = false;
        state.error = action.error;
      })
  },
});

export default resetPasswordSlice.reducer;
