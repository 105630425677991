import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showError, showSuccess } from '../../helpers/toast';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    // Display error message
    console.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);


// Async thunk for updating a company
export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async ({ companyid, companyData, accessToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.put(`/company/${companyid}`, companyData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update company');
    }
  }
);


// Async thunk for get by id a company
export const getbyidCompany = createAsyncThunk(
  'companies/getbyidCompany',
  async ({ companyId, companyData }, { rejectWithValue }) => {
    console.log(companyId)
    try {
      const config = {
        headers: {
          'Authorization': `${companyData.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.get(`/company/${companyId}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update company');
    }
  }
);



// Async thunk for fetching companies
export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async (accessToken, { rejectWithValue }) => {
    try {
        const config = {
            headers: {
              'Authorization': `${accessToken}`,
              'Content-Type': 'application/json',
            },
          };
      const response = await API.get('/company/',config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch companies');
    }
  }
);

const initialState = {
  companies: [],
  loading: false,
  error: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: {
    [updateCompany.fulfilled]: (state, action) => {
      state.loading = false;
        state.companies = action.payload;
      state.error = null;
    },
    [fetchCompanies.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies = action.payload;
      state.error = null;
    },
    [fetchCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getbyidCompany.pending]: (state) => {
      state.loading = true;
    },
    [getbyidCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies= action.payload;
      state.error = null;
    },
    [getbyidCompany.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default companiesSlice.reducer;
