import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import iconAddMore from "../../../../assets/media/icon-add-more.svg";
import iconFieldDel from "../../../../assets/media/icon-del-field.svg";
import iconEditHead from "../../../../assets/media/icon-editor-header.svg";
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';


const Tables = () => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className='editorHeader'>
                <h2 className='subtitle'>
                    <Link onClick={() => dispatch(setSelectedControl('Controls'))}><img src={iconEditHead} alt="" /></Link>
                    <span>Tables</span>
                </h2>
            </div>
            <div className='editorBody'>
                <div className='itemOptions'>
                    <div className='itemOption'>
                        <label>Margin</label>
                        <div className='itemProp'>
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                            <input className='form-control' type='text' value='0' />
                        </div>
                        <div className='itemAttr'>
                            <span>Top</span>
                            <span>Right</span>
                            <span>Bottom</span>
                            <span>Left</span>
                        </div>
                    </div>
                    <div className='itemOption'>
                        <label>Horizontal Alignment</label>
                        <select class="form-select">
                            <option selected value="Left">Left</option>
                            <option value="Center">Center</option>
                            <option value="Right">Right</option>
                        </select>
                    </div>
                    <div className='itemOption'>
                        <label>Vertical Alignment</label>
                        <select class="form-select">
                            <option value="Top">Top</option>
                            <option selected value="Middle">Middle</option>
                            <option value="Bottom">Bottom</option>
                        </select>
                    </div>
                    <div className='itemOption separator'>
                    </div>
                    <div className='itemOptionGroup'>
                        <div className='itemOption'>
                            <label>Columns</label>
                            <select class="form-select">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option selected value="5">5</option>
                            </select>
                        </div>
                        <div className='itemOption'>
                            <label>Rows</label>
                            <select class="form-select">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option selected value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div className='itemOption'>
                        <label>Column titles</label>
                        <div className="inputGroup">
                            <div className='inputGroupWrap'>
                                <span>#1</span>
                                <input type="text" className="form-control" value="Nature of Claim" />
                            </div>
                            <img src={iconFieldDel} alt="" />
                        </div>
                        <div className="inputGroup">
                            <div className='inputGroupWrap'>
                                <span>#2</span>
                                <input type="text" className="form-control" value="Case Number" />
                            </div>
                            <img src={iconFieldDel} alt="" />
                        </div>
                        <div className="inputGroup">
                            <div className='inputGroupWrap'>
                                <span>#3</span>
                                <input type="text" className="form-control" value="Identity of Parties" />
                            </div>
                            <img src={iconFieldDel} alt="" />
                        </div>
                        <div className="inputGroup">
                            <div className='inputGroupWrap'>
                                <span>#4</span>
                                <input type="text" className="form-control" value="Quantum of Claim" />
                            </div>
                            <img src={iconFieldDel} alt="" />
                        </div>
                        <div className="inputGroup">
                            <div className='inputGroupWrap'>
                                <span>#5</span>
                                <input type="text" className="form-control" value="Current Status of Case" />
                            </div>
                            <img src={iconFieldDel} alt="" />
                        </div>


                        <Link className='addMoreButton' to={'/'}><img src={iconAddMore} alt="" />Add Column</Link>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default Tables