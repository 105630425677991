import React, { useEffect, useState } from 'react'
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconPaper from "../../../assets/media/icon-paper.svg";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";

import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import Pagination from '../dashboard/appPagination';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '.';
import { fetchApplications } from '../../../store/reducers/applicationSlice';

const AppDiscrepancies = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const applications = useSelector((state) => state.applications);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const [filteredApplication, setFilteredApplication] = useState([]);

  useEffect(() => {
    dispatch(fetchApplications({ accessToken: authToken }));
  }, [authToken, dispatch]);
  

useEffect(() => {
  if (applications.data.length > 0 && getallUserdata.length > 0) {
      const mergedData = applications.data.map((appItem) => {
          const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
          if (userItem) {
              return {
                  ...appItem,
                  applicationId: appItem.id, 
                  ...userItem,
                  userId: userItem.id, 
              };
          }
          return { ...appItem, applicationId: appItem.id }; 
      });

      const filteredData = mergedData.filter((item) =>
      item.company_id === user.user.company_id &&
      item.state === "discrepancies" &&
      Object.values(item).some((value) =>
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
  );

      setFilteredApplication(filteredData);
      console.log(filteredData, 'Filtered Data');
  }
}, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTotalPages = () => {
    if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
    return Math.floor(filteredApplication.length / 10) + 1;
  };

  const handleDownload = (item) => {
    try {
        const parsedItem = JSON.parse(item);
        console.log(parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url,'hggggsgggggg')
        const fileUrl = parsedItem[0]?.sections[0]?.column[0]?.row[1]?.content[0]?.url;
        if (fileUrl) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', fileUrl, true);
            xhr.responseType = 'blob';
            
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const blob = xhr.response;
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'downloaded_image.jpg';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Failed to download the file.');
                }
            };

            xhr.onerror = function () {
                console.error('Failed to download the file.');
            };
            
            xhr.send();
        } else {
            console.error('File URL not found in the JSON data.');
        }
    } catch (error) {
        console.error('Error parsing item string:', error);
    }
};


  return (
    <div>
      <Navbar />
      <Header />
      <div className='wrapper'>
        <div className='boxAppBoard boxWhite'>
          <div className='boardHeader'>
            <div className='boardHeadLeft'>
              <h2>Renewals</h2>
            </div>
            <div className='boardHeadRight'>
              <div className='searchForm'>
                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                <button><img src={iconSearch} alt='' /></button>
              </div>
              <div className='datePicker'>
              </div>
            </div>
          </div>
          <div className='boardContent'>
            <div className='boardThead'>
              <div className='boardCol w-85'>
                S.NO.
              </div>
              <div className='boardCol w-165'>
                Application No.
              </div>
              <div className='boardCol w-adj4'>
                Application Name
              </div>
              <div className='boardCol w-165'>
                Due Date
              </div>
              <div className='boardCol w-165'>
                Last Registered
              </div>
              <div className='boardCol w-165'>
              </div>
              <div className='boardCol w-110'>

              </div>
            </div>
            {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).slice((currentPage - 1) * 10, currentPage * 10).map((item, index) => (
              <div className='boardTrow' key={item.id}>
                <div className='boardCol w-85'>
                  {index + 1}
                </div>
                <div className='boardCol w-165'>
                  {`GB-000000${item.applicationId}`}
                </div>
                <div className='boardCol w-adj4'>
                  <div className='boardTitle'>
                    <strong>{item.name}</strong>
                    <span>{item.form}</span>
                  </div>
                </div>
                <div className='boardCol w-165'>
                {item?.updated_at.slice(0, 10)}
                </div>
                <div className='boardCol w-165'>
                {item?.updated_at.slice(0, 10)}
                </div>
                <div className='boardCol w-165'>
                  <div className='boardDownload'>
                    
                  </div>
                </div>
                <div className='boardCol w-110'>
                  <div className='boardActions'>
                    <Link to={'/'}><img src={iconPaper} alt='' /></Link>
                    <img onClick={() => handleDownload(item.json_data)} src={iconFileDownload} alt='' />
                  </div>
                </div>
              </div>))}


          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  )
}
export default AppDiscrepancies