import React, { useEffect, useState } from 'react';
import iconLogo from "../../../assets/media/icon-logo.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveItem, setApplyingForm } from '../../../store/reducers/counterSlice';
import { fetchForms } from '../../../store/reducers/formSlice';
import classes from './style.module.css'
import FormContent from './FormContent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"
import { postApplication } from '../../../store/reducers/applicationSlice';
import { uploadFile } from '../../../store/reducers/applicationSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import { showSuccess } from '../../../helpers/toast';
import Form1 from '../forms/form_1'
// import Form from './form';
import FormHeader from '../forms/formHeader/formHeader';

const AppForm = () => {

    const [formIds, setFormIds] = useState([]);
    const [inputFields, setInputFields] = useState({});
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const accessToken = useSelector((state) => state.auth.user.access_token);
    const categories = useSelector((state) => state.categories);
    const user = useSelector((state) => state.auth.user);
    const { applyingFormData } = useSelector((state) => state.counter)

    const dispatch = useDispatch();

    useEffect(() => {
        const elements = document.querySelectorAll('[id^="form"]');
        const ids = Array.from(elements)
          .filter(element => /^form\d+_\d+$/.test(element.id))
          .map(element => element.id);
        setFormIds(ids);
        setPageIds(getPageIds());
      }, []);
   
    
      useEffect(()=>{
        // console.log(pageIds);
        if(pageIds.length>0){
            pageIds.forEach(id => {
                if(id.replace(/^page/, '')!=currentPage){
                    const pageDiv = document.getElementById(id);
                    if (pageDiv) {
                      pageDiv.style.display = "none";
                    }
                }    
              });
        }
        console.log(pageIds.length);
      },[pageIds])

    // console.log({ location,navigate })




    // console.log({values:formik.values,errors:formik.errors,form})



   
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (formIds.length > 0) {
          attachEventHandlers();
        }
      }, [formIds.length]);
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputFields(prevInputFields => ({
          ...prevInputFields,
          [id]: value,
        }));
      
      };
    const attachEventHandlers = () => {
        formIds.forEach(id => {
          const inputField = document.getElementById(id);
          if (inputField) {
            // console.log("adding event lisnter");
            inputField.addEventListener('change', handleInputChange);
          }
        });
      };
      const handleSubmitWithLoading = (e) => {
        setIsLoading(true);
        // dispatch(createNotification({
        //     message: 'Submit New Application',
        //     user_id: user.user.id,
        //     accessToken: accessToken,
        //     is_admin: true
        // }));

        dispatch(postApplication({
            form:  applyingFormData?.category,
            json_data: JSON.stringify(inputFields),
            name: applyingFormData?.name,
            category: applyingFormData?.category,
            user_id: user.user.id,
            token: accessToken
        }))
        setIsLoading(false);
    };

     const getPageIds = () => {
        const elements = document.querySelectorAll('[id^="page"]');
        const ids = Array.from(elements)
          .filter(element => /^page\d+$/.test(element.id))
          .map(element => element.id);
        return ids;
      };
    const previousPage = () =>{
      if(currentPage-1>=1){
        hideDiv(currentPage);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1);
      }
    }
  
    const nextPage = () =>{
      if(currentPage+1<=pageIds.length){
        hideDiv(currentPage);
        showDiv(currentPage+1);
        setCurrentPage(currentPage+1);
      }
    }
    const hideDiv=(pageNo)=>{
      const pageDiv = document.getElementById("page"+pageNo);
      pageDiv.style.display = "none"; 
    }

    const showDiv=(pageNo)=>{
      const pageDiv = document.getElementById("page"+pageNo);
      pageDiv.style.display = ""; 
    }

    
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>                    
                <FormHeader applicationName={"applicationName"} nextPage={nextPage} previousPage={previousPage} currentPage={currentPage} totalPages={pageIds.length}/>

                    <div className={classes['board-content']}>
                    <button
                                type='submit'
                                style={{
                                    backgroundColor: '#114df2',
                                    color: 'white',
                                    padding: 5,
                                    float: 'right',
                                    border: '1px solid #114df2',
                                    borderRadius: 5,
                                    width: '15%'
                                }}
                                onClick={handleSubmitWithLoading}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <>
                                        <div className="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status"></div>
                                        <span className="sr-only ml-2">Loading...</span> 
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </button>

                            <Form1/>
                            {/* {
                            form?.map((page,pageIndex) => (
                                <>
                                {page?.id === currentPage && <Form page={page} currentPage={currentPage} pageIndex={pageIndex} classes={classes} />
}                                </>
                            ))
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppForm