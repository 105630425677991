import React, { Fragment, useEffect } from 'react'
import logo from "../../../assets/media/logo.png";
import booksAnimation from "../../../assets/media/books-animation.gif";
import iconMark from "../../../assets/media/icon-mark.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import GBInput from '../../input';
import * as Yup from 'yup';
import { signUp } from '../../../store/reducers/signUpSlice';
import { loginUser } from '../../../store/reducers/authSlice';

const SignUp3 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signUpUser = useSelector((state) => state.signUp);
    const loading = useSelector((state) => state.signUp.loading);
    const user = useSelector((state) => state.auth.user);
    const signupData = useSelector((state) => state?.signUp?.data?.payload);



    const initialValues = {
        otp: '',
    };


    const jwt = signUpUser.signupcode
    const claims = jwt && JSON.parse(atob(jwt.split('.')[1]))
    const otp = claims?.secretPin
    const validationSchema = Yup.object({
        otp: Yup.string().required('Otp is required').oneOf([String(claims?.secretPin)], 'Enter correct OTP code'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(signUp({ userData: { signupcode: signUpUser.signupcode, password: signUpUser.password, full_name: signUpUser.full_name, phone: signUpUser.phone, companyRegistrationNo: signUpUser.companyRegistrationNo } }));
            try {
                dispatch(loginUser({ emailOrPhone: signUpUser.message.user.email, password: signUpUser.password }, navigate));
            } catch (error) {
            }
        },
    });


    useEffect(() => {
        if (user?.access_token || signupData?.access_token) {
            navigate('/');
        }
    }, [navigate, user, signupData]);

    const { values, handleChange, handleSubmit, errors } = formik;

    const fields = [
        {
            name: 'otp',
            label: 'OTP',
            type: 'text',
            placeholder: 'Enter OTP',
            value: values.otp,
            onChange: handleChange,
            iconSrc: iconMark
        }
    ];

    return (
        <div className="App">
            <div className="container">
                <div className="landingWrapper">
                    <div className="landingRow">
                        <div className="landingCol">
                            <div className="fieldsWrapper">
                                <img src={logo} className="logo" alt="logo" />
                                <h2>Signup With Us</h2>
                                <p>We have sent OTP on your given number</p>

                                <form className='gb-login-form' onSubmit={handleSubmit}>

                                    {
                                        fields.map((field, index) => {
                                            return <Fragment key={index}>
                                                <GBInput
                                                    key={index}
                                                    name={field.name}
                                                    type={field.type}
                                                    label={field.label}
                                                    value={field.value}
                                                    placeholder={field.placeholder}
                                                    setValue={handleChange}
                                                    iconSrc={field.iconSrc}
                                                />
                                                {
                                                    errors[`${field.name}`] && <div className="error-message error-message-wrap">{errors[`${field.name}`]}</div>
                                                }
                                            </Fragment>
                                        })
                                    }
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckChecked"
                                            checked
                                        />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            I agree to <a href="#">Terms of Service</a> &{" "}
                                            <a href="#">Privacy Policy</a>
                                        </label>
                                    </div>
                                    <div className="gb-form-footer">

                                        <button type="submit" style={{ background: values.otp !== String(claims?.secretPin) && "#ccc", border: values.otp !== String(claims?.secretPin) && "solid 1px #ccc" }} disabled={!(values.otp === String(claims?.secretPin))} className="form-submit">
                                            {loading ?
                                                <>
                                                    <div class="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status">
                                                    </div>
                                                    <span class="sr-only ml-5"> Loading...</span>
                                                </>
                                                :
                                                "Sign Up"
                                            }
                                        </button>
                                    </div>
                                </form>
                                <div className="gb-step-list">
                                    <span className="current"></span>
                                    <span className="current"></span>
                                    <span className="current"></span>
                                </div>
                            </div>
                        </div>
                        <div className="landingCol">
                            <div className="imageWrapper">
                                <div className="loginBox">
                                    <p>
                                        Already have account? <Link to={'/'} >Sign In</Link>
                                    </p>
                                </div>
                                <img src={booksAnimation} className="booksAnimation" alt="" />
                                <h3>
                                    <span>Paper Less</span>
                                    <strong>Documentations</strong>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp3