import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showError, showSuccess } from '../../helpers/toast';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    // Display error message
    console.error(error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

// Async thunk for creating a cabinet
export const createcabinet = createAsyncThunk(
  'cabinets/createcabinet',
  async ({ folder, user_id, accessToken, company_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.post('/cabinet/', { folder, user_id ,company_id}, config);
      showSuccess('Record created successfully');
      return response.data;
    } catch (error) {
      showError(error.response.data.error);
      return rejectWithValue(error.response?.data?.message || 'Failed to create cabinet');
    }
  }
);

// Async thunk for updating a cabinet
export const updatecabinet = createAsyncThunk(
  'cabinets/updatecabinet',
  async ({ cabinetId, message, read_status, accessToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.put(`/cabinet/${cabinetId}`, { message, read_status }, config);
    //   showSuccess('cabinet updated successfully');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update cabinet');
    }
  }
);

// Async thunk for deleting a cabinet
export const deletecabinet = createAsyncThunk(
  'cabinets/deletecabinet',
  async ({configdata}, { rejectWithValue }) => { 
    console.log(configdata.cabinets_ids,"nnnnnnnnnnnnnnnnn")
    try {
      const config = {
        headers: {
          'Authorization': `${configdata.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.delete(`/cabinet/${configdata.cabinets_ids}`, config);
    //   showSuccess('cabinet deleted successfully');
      return configdata.cabinets_ids;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete cabinet');
    }
  }
);

// Async thunk for fetching cabinets
export const fetchcabinets = createAsyncThunk(
  'cabinets/fetchcabinets',
  async (accessToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await API.get('/cabinet/', config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch cabinets');
    }
  }
);

const initialState = {
  cabinets: [],
  loading: false,
  error: null,
};

const cabinetslice = createSlice({
  name: 'cabinets',
  initialState,
  reducers: {},
  extraReducers: {
    [createcabinet.pending]: (state) => {
      state.loading = true;
    },
    [createcabinet.fulfilled]: (state, action) => {
      state.loading = false;
      state.cabinets.push(action.payload);
      state.error = null;
    },
    [createcabinet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updatecabinet.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.cabinets.findIndex(cabinet => cabinet.id === action.payload.id);
      if (index !== -1) {
        state.cabinets[index] = action.payload;
      }
      state.error = null;
    },
    [deletecabinet.fulfilled]: (state, action) => {
      state.loading = false;
      state.cabinets = state.cabinets.filter(cabinet => cabinet.id !== action.payload);
      state.error = null;
    },
    [fetchcabinets.pending]: (state) => {
      state.loading = true;
    },
    [fetchcabinets.fulfilled]: (state, action) => {
      state.loading = false;
      state.cabinets = action.payload;
      state.error = null;
    },
    [fetchcabinets.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default cabinetslice.reducer;
