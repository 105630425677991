import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import Controls from './controls';
import Sections from './controls/section';
import Buttons from './controls/buttons';
import Fields from './controls/fields';
import Images from './controls/images';
import Tables from './controls/tables';
import Upload from './controls/upload';
import Texts from './controls/texts';
import { useSelector } from 'react-redux';

const AppFormCreation = () => {

    const selectedControl = useSelector((state) => state.counter.selectedControl || 'Controls')
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>

                <div className='profHeader'>
                    <div className='profHeadLeft'>
                        <h2>Create Forms</h2>
                    </div>
                    <div className='profHeadRight'>
                        <div className='btngroup'>
                            <Link className='buttonSecondary' to={'/'}>Save In Draft</Link>
                            <Link className='buttonPrimary' to={'/'}>Discard Form</Link>
                        </div>
                    </div>
                </div>
                <div className='sp20'></div>
                <div className='AppFormBar noShadow'>
                    <div className='title'>
                        Category Name / Application Name / Page Name
                    </div>
                    <div className='pagination'>
                        <div className='pageNum'>
                            <span className='pageCurrent'>1</span> <span className='slash'>/</span> 10
                        </div>
                    </div>
                </div>
                <div className='editorWrapper'>
                    <div className='editorBar'>
                        {selectedControl === 'Controls' && <Controls />}
                        {selectedControl === 'Sections' && <Sections />}
                        {selectedControl === 'Texts' && <Texts />}
                        {selectedControl === 'Buttons' && <Buttons />}
                        {selectedControl === 'Fields' && <Fields />}
                        {selectedControl === 'Images' && <Images />}
                        {selectedControl === 'Tables' && <Tables />}
                        {selectedControl === 'Upload' && <Upload />}
                        {selectedControl === 'Divider' && <Upload />}
                        {selectedControl === 'Pages' && <Upload />}
                        <div className='editorFooter'>
                            <div className='btngroup'>
                                <Link className='buttonSecondary' to={'/'}>View Form</Link>
                                <Link className='buttonPrimary' to={'/'}>Publish</Link>
                            </div>
                        </div>
                    </div>
                    <div className='AppFormWrapper'>
                        <div className='AppFormHeader'>
                            <h2>Application for Renewal: Gaming Licence</h2>
                            <h6>Application No. <b>GBB 1.a.3</b></h6>
                        </div>
                        <div className='AppFormContent'>

                        </div>
                        <div className='AppFormFooter'>
                            <div className='appFootLeft'>
                                <button type='submit' className='reset'>Clear Form</button>
                            </div>
                            <div className='appFootRight'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppFormCreation