import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployee, deleteEmployee } from '../../../store/reducers/employeeSlice.js';
import { fetchEmployee } from '../../../store/reducers/employeeSlice.js';
import Pagination from '../dashboard/appPagination';
import { createNotification } from '../../../store/reducers/notificationSlice.js';
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
import { deleteUser, getallUser } from '../../../store/reducers/userSlice.js';

const AppEmployees = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const employee = useSelector((state) => state.employee);
    const createLoading = useSelector((state) => state.employee.createLoading);
    const fetchLoading = useSelector((state) => state.employee.fetchLoading);
    const [searchQuery,setSearchQuery] = useState('');
    const [filteredemployee,setFilteredEmplyee] = useState([]);
    

    const users = employee?.users
    const authToken = user.access_token;

    const initialValues = {
        full_name: '',
        national_id_number: '',
        email: '',
        phone: '',
    };

    const validationSchema = Yup.object({
        full_name: Yup.string()
            .min(2, 'Name must be at least 2 characters')
            .required('Full Name is required'),
        national_id_number: Yup.string()
            .matches(/^\d{3}\.\d{3}\.\d{4}$/, 'Invalid ID number format'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phone: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format')
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            dispatch(addEmployee({
                userData: {
                    full_name: values.full_name,
                    national_id_number: values.national_id_number,
                    email: values.email,
                    phone: values.phone,
                    company_id : user.user.company_id
                },
                accessToken: authToken,
            }));
            dispatch(createNotification({
                message:'Create Employee', 
                user_id:user.user.id, 
                accessToken :authToken,
                is_admin:true
            }))
        },
    });

    const { values, handleChange, handleSubmit, handleBlur, errors } = formik;

    const getTotalPages = () => {
        if (Number.isInteger(data?.length / 10)) return data?.length / 10;
        return Math.floor(data?.length / 10) + 1
    }

    useEffect(() => {
        dispatch(fetchEmployee({ accessToken: authToken }));
    }, [authToken, dispatch]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        setFilteredEmplyee(users.filter((item) => {
            return item.is_deleted != true && item.company_id === user.user.company_id && item.active === true && item.verified === true && Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
        }).sort((a, b) => b.id - a.id));
    }, [users, searchQuery]);

    const deleteuserdata = async (email) => {
        try {
            await dispatch(deleteEmployee({ email: email, accessToken: authToken }))
            dispatch(fetchEmployee({ accessToken: authToken }));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };
    
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader noMg'>
                        <div className='boardHeadLeft'>
                            <h2>Employees</h2>
                        </div>
                    </div>
                </div>
                <div className='empAdd'>
                    <h3 className='noMg'>Add Employee </h3>
                    <form className='addEmployee-form' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Full Name</label>
                                    <input
                                        name="full_name"
                                        type='text'
                                        placeholder='Marvin McKinney'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.full_name}
                                        className={errors.full_name ? 'error-input' : ''}
                                    />
                                    {
                                        errors.full_name && <div className="error-message">{formik.errors.full_name}</div>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>National Identity Number</label>
                                    <input
                                        name="national_id_number"
                                        type='text'
                                        placeholder='123.123.1233'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.national_id_number}
                                        className={errors.national_id_number ? 'error-input' : ''}
                                    />
                                    {
                                        errors.national_id_number && <div className="error-message">{formik.errors.national_id_number}</div>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Email Address</label>
                                    <input
                                        name="email"
                                        type='text'
                                        placeholder='email@gamingboard.com'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        className={errors.email ? 'error-input' : ''}
                                    />
                                    {
                                        errors.email && <div className="error-message">{formik.errors.email}</div>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap'>
                                    <label>Phone No.</label>
                                    <input
                                        name="phone"
                                        type='text'
                                        placeholder='(702) 555-0122'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        className={errors.phone ? 'error-input' : ''}
                                    />
                                    {
                                        errors.phone && <div className="error-message">{formik.errors.phone}</div>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className='fieldWrap noLabel'>
                                    <button type='submit' disabled={createLoading}>
                                        {createLoading ?
                                            <>
                                                <div class="text-xs spinner-border text-light" style={{ width: '1rem', height: '1rem' }} role="status">
                                                </div>
                                                <span class="sr-only ml-5"> Loading...</span>
                                            </>
                                            :
                                            "Add Employee"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div >
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>All Employees</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery}/>
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Employee Id
                            </div>
                            <div className='boardCol w-adj2'>
                                Name
                            </div>
                            <div className='boardCol w-320'>
                                Email
                            </div>
                            <div className='boardCol w-200'>
                                Phone No.
                            </div>
                            <div className='boardCol w-165'>
                                Create Date
                            </div>
                            <div className='boardCol w-110'>
                            </div>
                        </div>
                        {
                            fetchLoading ?
                                <div className='d-flex justify-content-center my-4'>
                                    <div class=" text-center items-center spinner-border text-primary" style={{ width: '4rem', height: '4rem' }} role="status">
                                    </div>
                                </div>
                                :
                                <>
                                    {filteredemployee.length > 0 ?
                                        <>
                                            {filteredemployee.map((item, index) => (
                                                <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} key={item.id} >
                                                    <div className='boardCol w-85'>
                                                        {index + 1}
                                                    </div>
                                                    <div className='boardCol w-165'>
                                                    {`GB-000000${item.id}`}
                                                    </div>
                                                    <div className='boardCol w-adj2'>
                                                        <div className='boardTitle widAvatar'>
                                                            <img className='avatar' src={item.image != '' ? item.image : userImagePlaceholder} alt="" />
                                                            <strong>{item.full_name}</strong>
                                                            <span>{item.national_id_number || '--'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='boardCol w-320'>
                                                        <a href="mailto:deanna.curtis@example.com">{item.email}</a>
                                                    </div>
                                                    <div className='boardCol w-200'>
                                                        <a href="tel:(702) 555-0122">{item.phone}</a>
                                                    </div>
                                                    <div className='boardCol w-165'>
                                                        {item.created_at?.slice(0, 10)}
                                                    </div>
                                                    <div className='boardCol w-110'>
                                                        <div className='boardActions' onClick={() => {
                                                            
                                                            deleteuserdata(item.email)
                                                        }}>
                                                            <img  style={{cursor:'pointer'}} src={iconFileDelete} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </> : <h2 className='text-danger text-center my-4 py-4'>Employees not Found</h2>
                                    }
                                </>
                        }


                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    // totalPages={() => 10}
                    />
                </div>
            </div >
        </div >
    )
}
export default AppEmployees