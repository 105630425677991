import React, { Fragment } from 'react'
import iconElemDown from "../../../../assets/media/icon-elem-down.png";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedControl } from '../../../../store/reducers/counterSlice';

const Controls = () => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className='editorHeader'>
                <h2>Form Elements</h2>
            </div>
            <div className='editorBody'>
                <div className='elementList'>
                    <Link onClick={() => dispatch(setSelectedControl('Pages'))} className='elementItem'>
                        <span>Pages</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Sections'))} className='elementItem'>
                        <span>Sections</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Texts'))} className='elementItem'>
                        <span>Text</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Fields'))} className='elementItem'>
                        <span>Fields</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Tables'))} className='elementItem'>
                        <span>Table</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Upload'))} className='elementItem'>
                        <span>Upload</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Images'))} className='elementItem'>
                        <span>Images</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Buttons'))} className='elementItem'>
                        <span>Buttons</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                    <Link onClick={() => dispatch(setSelectedControl('Divider'))} className='elementItem'>
                        <span>Divider</span>
                        <img src={iconElemDown} alt="" />
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

export default Controls