// reducers/applicationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showError, showSuccess } from '../../helpers/toast';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL

});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
  application: {}
};

export const fetchApplications = createAsyncThunk('fetchApplications', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.get('/applications', config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to load applications');
  }
});
export const fetchApplication = createAsyncThunk('fetchApplication', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.get('/applications/'+data?.id, config);
    return response.data;
  } catch (error) {
    throw new Error('Failed to load application');
  }
});
export const postApplication = createAsyncThunk('postApplication', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.token}`,
      },
    };
    console.log({data})
    const response = await API.post('/applications', {
      form:data?.form,
      json_data:data?.json_data,
      name:data?.name,
      category:data?.category,
      user_id:data?.user_id

    },config);

    showSuccess('Create Successfully!');
    return response.data;
  } catch (error) {
    throw new Error('Failed to load applications');
  }
});
export const putApplication = createAsyncThunk('putApplication', async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${data.accessToken}`,
      },
    };
    const response = await API.put('/applications/'+data?.id, {
      json_data:data?.json_data,
      name:data?.name

    }, config);

    showSuccess('Update Successfully!');
    return response.data;
  } catch (error) {
    throw new Error('Failed to load application');
  }
});

export const uploadFile = createAsyncThunk('uploadFile', async (data) => {
  const {accessToken, payload} = data
  
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${accessToken}`,
      },
    };
    const response = await API.post('/applications/upload-file', payload, config);
    
    return response.data;
  } catch (error) {
    throw new Error('Failed to upload file');
  }
});

const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      })

      .addCase(fetchApplication.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        console.log('action', action.payload)
        state.fetchLoading = false;
        state.application = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplication.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.application = null;
      });
  },
});

export default applicationSlice.reducer;
